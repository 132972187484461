const $ = window.Bliss;

export class Toggle {
    constructor() {
        this.isOpen = false

        document.body.addEventListener('click.toggle', (e) => {
            if (e.target.closest('[data-toggle]')) {
                this.isOpen = true
                this.target = e.target && e.target.closest('[data-toggle]');
                this.target.classList.toggle('toggle--collapsed');
            } else {
                if (this.isOpen) {
                    this.target.classList.remove('toggle--collapsed');
                    this.isOpen = false
                    return () => {
                        document.body._.unbind("click.toggle")
                    }
                }
            }
        });
        document.addEventListener('keydown.toggle', (e) => {
            if (e.key === "Escape" && this.isOpen) {
                this.target.classList.remove('toggle--collapsed');
                this.isOpen = false
            }
            return () => {
                document.body._.unbind("keydown.toggle")
            }
        })

    }
}

window.addEventListener('load', () => {
    let breakpoint = document.querySelector('.show-breakpoint');
    let showColumn = document.querySelector('.show-columns');

    // const body = document.querySelector("body")

    // if (body) {
    //     new showSpacings(body)
    // }

    if (breakpoint) {
        breakpoint.addEventListener('click', () => {
            showColumn.classList.toggle('active');
        });
    }

    let fontSelector = document.querySelectorAll('[data-helper-font-selector]');

    if (fontSelector) {
        fontSelector.forEach((el) => new showFontFamily(el));
    }

    let colorSelector = document.querySelectorAll(
        '[data-helper-color-selector]'
    );

    if (colorSelector) {
        colorSelector.forEach((el, i) => new showColorValue(el, i));
    }
});

function css(element, property) {
    return window.getComputedStyle(element, null).getPropertyValue(property);
}

const rgba2hex = (rgba) =>
    `#${rgba
        .match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)
        .slice(1)
        .map((n, i) =>
            (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
                .toString(16)
                .padStart(2, '0')
                .replace('NaN', '')
        )
        .join('')}`;

class showColorValue {
    constructor(el, index) {
        this.selector = el;
        this.index = index;
        this.color = this.selector.querySelectorAll('[data-helper-color-item]');

        this.color.forEach((el, i) => this.getColorValue(el, i, this.index));
    }

    getColorValue = (color, index, parentIndex) => {
        this.expInfoContainer = color.querySelector('[data-color-exp-info]');
        let exp = document.createElement('span');
        exp.classList.add(
            'styleguide__color-value',
            `styleguide__color-value-${parentIndex}-${index}`
        );
        let backgroundColor = color.querySelector('.styleguide__color-item');
        let style = document.createElement('style');
        style.textContent = `.styleguide__color-value-${parentIndex}-${index}::after { content: "${rgba2hex(
            css(backgroundColor, 'background-color')
        )}";}`;

        this.expInfoContainer.appendChild(exp);
        this.expInfoContainer.appendChild(style);
    };
}
class showFontFamily {
    constructor(el) {
        this.selector = el;
        this.font = this.selector.querySelector('[data-helper-font]');
        this.output = this.selector.querySelector('[data-helper-font-output]');
        this.styleOutput = this.selector.querySelector(
            '[data-helper-font-output-styles]'
        );
        this.fontWeights = {
            300: 'Light',
            400: 'Regular',
            500: 'Medium',
            600: 'Semi-Bold',
            700: 'Bold',
        };

        this.getFontFamily(this.font);
    }

    getFontFamily = (font) => {
        let fontFamily = css(font, 'font-family');
        let fontSize = css(font, 'font-size');
        let fontWeight = css(font, 'font-weight');
        let lineHeight = css(font, 'line-height');
        let letterSpacing = css(font, 'letter-spacing');
        let lineheightNum = parseFloat(
            (lineHeight.slice(0, -2) / fontSize.slice(0, -2)).toFixed(2)
        );
        let parsedFontSize = parseFloat(fontSize).toFixed(0);
        let displayFontWeight =
            this.fontWeights[fontWeight] === undefined
                ? fontWeight
                : this.fontWeights[fontWeight];
        if (fontFamily) {
            this.output.innerText = `${fontFamily} – ${displayFontWeight}`;
            if (letterSpacing !== 'normal') {
                this.styleOutput.innerText = `${parsedFontSize}px, ${lineheightNum}em, ${letterSpacing}`;
            } else {
                this.styleOutput.innerText = `${parsedFontSize}px, ${lineheightNum}em`;
            }
        }
    };
}

// class showSpacings {
//     constructor(el) {
//         this.selector = el;
//         this.spacing = this.selector.querySelectorAll('.y-scale');
//
//         this.spacing.forEach(el => {
//             let marginBottom = css(el, 'margin-bottom');
//             let margintop = css(el, 'margin-top');
//             el.style.borderBottom = `${marginBottom} solid #ffcccc`
//             el.style.borderTop = `${margintop} solid #ffb8b8`
//             el.style.marginBottom = 0
//             el.style.marginTop = 0
//             console.log(marginBottom);
//         })
//
//         this.getSpacings(this.spacing);
//     }
//
//     getSpacings = (font) => {
//
//     };
// }

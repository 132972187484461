import {triggerClassOnScroll} from './trigger-class-on-scroll';

$(() => {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Header

    IMPORTANT: If the 'header buffer' is enabled, the scroll distance breakpoints have to be the same height (AT LEAST), otherwise the buffer becomes visible.

    */

    function initHeader() {
        if (document.querySelector('.site-header.compact-mode-enabled')) {
            // const scrollDistance = 1
            let scrollDistance = 0

            if (document.querySelector('[data-hero-image]')) {
                scrollDistance = document.querySelector(
                    '[data-hero-image]'
                ).offsetHeight;
            }

            triggerClassOnScroll({
                scroll: scrollDistance,
                class_to_trigger: 'compact-mode',
                position: window,
                window: true,
                element: document.querySelector(
                    '.site-header.compact-mode-enabled'
                ),
            });
            triggerClassOnScroll({
                scroll: scrollDistance,
                class_to_trigger: 'header-compact-mode',
                position: window,
                window: true,
                element: document.querySelector('html'),
            });
        }
    }

    function initSoftpageHeader() {
        if (document.querySelector('.softpage-visible') && document.querySelector('[data-hero-image]')) {
            const scrollDistance = document.querySelector(
                '.softpage-visible .tingle-modal-box [data-hero-image]'
            ).offsetHeight;
            triggerClassOnScroll({
                scroll: scrollDistance,
                position: document.querySelector(".tingle-modal"),
                class_to_trigger: 'softpage-visible-compact',
                window: false,
                element: document.querySelector('html'),
            });
        }
    }

    // init on page load
    initHeader();

    // custom event
    $(window).on('initHeader', () => {
        initHeader();
    });

    $(window).on('initSoftpageTrigger', () => {
        initSoftpageHeader();
    });

    // initialize again when viewport width has changed
    $(window).on('viewportWidthHasChanged', () => {
        initHeader();
    });
});

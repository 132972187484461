import './modules/ajax-form';
import './modules/ajax-load-plugins';
import './modules/bootstrap-select';
import './modules/btn-ajax-loader';
import './modules/site-overlay';
import './modules/default-modal';
import './modules/infobox';
import './modules/djangocms-tweaks';
import './modules/expandable';
import './modules/feature-detection';
import './modules/form';
import './modules/form-modal';
import './modules/full-height';
import './modules/image-modal';
import './modules/lazysizes';
import './modules/pagechooser';
import './modules/seo';
import './modules/softpage';
import './modules/table';
import './modules/tingle';
import './modules/video';
import './modules/viewport-dimensions';
import './modules/form-validation';
import './modules/browser-recognition';
// import './modules/datepicker';

import {h, Fragment, render} from "preact"
import {useEffect, useRef, useState} from "preact/hooks"
import {useStoredState} from "./useStoredState.js"
import "blissfuljs"

const $ = window.Bliss
const $$ = $.$;

const {T} = window
const LS_KEY = "Watchlist"
// const T_DELETE_ALL = "Delete all"
// const T_SAFE_LIST = "Save"
// const T_ORDER_LIST = "Order list"
const VERSION = 1
const T_PRODUCTS = "product"
const WATCH_LIST_EMPTY = "Keine Produkte in der Merkliste"
const TYPES = [T_PRODUCTS]
const dataLayer = window.dataLayer
export const renderWatchList = el => {
    render(<WatchList/>, el)
}

function typeParams(watchList) {
    let params = new URLSearchParams()
    TYPES.forEach(type => {
        let ids = entriesOfType(watchList, type).map(entry => entry.id)
        if (ids.length) {
            params.append(type, ids.join(","))
        }
    })
    return params
}

function showMailView(watchList) {
    let params = typeParams(watchList)
    params.append("action", "mail")
    window.open(`${T("WATCH_LIST_VIEW")}?${params.toString()}`)
}

function showPrintView(watchList) {
    let params = typeParams(watchList)
    params.append("action", "print")
    window.open(`${T("WATCH_LIST_VIEW")}?${params.toString()}`)
}

function pushToDataLayer(event, category = 'Watchlist', action, labels) {
    if (labels.length > 1) {
        labels.forEach(label => {
            dataLayer.push({
                'event': event,
                'eventCategory': category,
                'eventAction': action,
                'eventLabel': label.number
            })
        })
    }

}

function removeFromWatchList(watchList, type, id) {
    return {
        ...watchList,
        [type]: entriesOfType(watchList, type).filter(entry => entry.id != id),
    }
}

function removeAllFromWatchList(watchList, type) {
    return {
        ...watchList,
        [type]: entriesOfType(watchList, type).filter(entry => entry.id == 0)
    }
}

function entriesOfType(watchList, type) {
    return watchList[type] || []
}

function toggleOnWatchList(watchList, data) {
    let entries = entriesOfType(watchList, data.type)
    if (entries.find(entry => entry.id == data.id)) {
        entries = entries.filter(entry => entry.id != data.id)
    } else {
        entries = [...entries, data]
    }
    return {...watchList, [data.type]: entries}
}

function WatchList() {
    const [hidden, setHidden] = useState(true) // Hidden by default
    const toggle = useRef()

    // Scrolling indicator state
    const watchListBody = useRef()
    const [scrollableTop, setScrollableTop] = useState(false)
    const [scrollableBottom, setScrollableBottom] = useState(false)

    const [watchList, setWatchList] = useStoredState({
        key: LS_KEY,
        version: VERSION,
    })

    useEffect(() => {
        // Instead of reimplementing delegation...
        document.body.addEventListener("click.watchlist", (e) => {
            if (e.target.closest('[data-watchlist-entry]')) {
                e.preventDefault()
                let target = e.target.closest('[data-watchlist-entry]')
                setHidden(false)
                if (!target.classList.contains("deactivate")) {
                    const script = target.querySelector("script")
                    const data = JSON.parse(script.textContent)
                    if (data) {
                        setWatchList(toggleOnWatchList(watchList, data))
                        target.classList.add("highlight")
                        if (target.dataset.productButton == "save" && target.classList.contains("highlight")) {
                            target.querySelector(".button-label").innerHTML = "Produkt entfernen"
                            target.querySelector(".button-mask__label").dataset.maskLabel = "Produkt entfernen"
                        } else if (target.dataset.productButton == "save" && !target.classList.contains("highlight")) {
                            target.querySelector(".button-label").innerHTML = "Produkt merken"
                            target.querySelector(".button-mask__label").dataset.maskLabel = "Produkt merken"
                        }
                    }
                }
            } else if (!e.target.closest('[data-watchlist-entry]') && !e.target.closest('[data-watchlist]')) {
                setHidden(true)
            }

        })
        return () => {
            document.body._.unbind("click.watchlist")
        }

    })
    useEffect(() => {
        document.addEventListener('keydown.watchlist', (e) => {
            if (e.key === "Escape") {
                setHidden(true)
            }
        })
        return () => {
            document._.unbind("keydown.watchlist")
        }
    })

    const products = entriesOfType(watchList, T_PRODUCTS)
    const count = products.length

    useEffect(() => {
        const identifiers = [
            ...products.map(entry => `${T_PRODUCTS}:${entry.id}`),
        ]

        $(document.body).addEventListener("change", () => {
            $$("[data-watchlist-entry]").forEach(el => {
                if (identifiers.indexOf(el.dataset.watchlistEntry) === -1) {
                    el.classList.remove("highlight")

                    if (el.dataset.productButton == "save" && !el.classList.contains("highlight")) {
                        el.querySelector(".button-label").innerHTML = "Produkt merken"
                        el.querySelector(".button-mask__label").dataset.maskLabel = "Produkt merken"
                    }

                } else {
                    el.classList.add("highlight")
                    if (el.dataset.productButton == "save" && el.classList.contains("highlight")) {
                        el.querySelector(".button-label").innerHTML = "Produkt entfernen"
                        el.querySelector(".button-mask__label").dataset.maskLabel = "Produkt entfernen"
                    }
                }
            })
        })

        $$("[data-watchlist-entry]").forEach(el => {
            if (!el.dataset.watchlistEntry) return
            if (identifiers.indexOf(el.dataset.watchlistEntry) === -1) {
                el.classList.remove("highlight")

                if (el.dataset.productButton == "save" && !el.classList.contains("highlight")) {
                    el.querySelector(".button-label").innerHTML = "Produkt merken"
                    el.querySelector(".button-mask__label").dataset.maskLabel = "Produkt merken"
                }
            } else {
                el.classList.add("highlight")

                if (el.dataset.productButton == "save" && el.classList.contains("highlight")) {
                    el.querySelector(".button-label").innerHTML = "Produkt entfernen"
                    el.querySelector(".button-mask__label").dataset.maskLabel = "Produkt entfernen"
                }
            }
        })
    })

    return (
        <div className={`watchlist ${hidden ? "watchlist--hidden" : ""} ${
            count ? "watchlist--hasitems" : ""
        }`}>
            <div
                className="watchlist-toggle card-interaction card-interaction--inverted counter"
                ref={toggle}
                title={T("LS_KEY")}
                role="button"
                href="#"
                onClick={e => {
                    e.preventDefault()
                    setHidden(!hidden)
                }}
            >
                <svg className="icon icon--small">
                    <use xlinkHref="#Icon-Pin" xlinkTitle={T("LS_KEY")}/>
                </svg>
                {count ? <span className="counter-badge text--small-4 filter-group__counter">{count}</span> : null}
            </div>

            <div className={`watchlist-panel ${count <= 4 ? "watchlist-panel--grow" : null}`}>
                <div
                    className="watchlist-panel__header flex align-items-center border--bottom border--brand-gray-60 x-scale-margin y-scale-padding x-equal-3-right x-equal-3-left y-equal-5-top y-equal-5-bottom">
                    <p className="text--tighter text--bold flex align-items-center">{T("LS_KEY")} {count ? <span
                        className="filter-group__counter bg--brand-gray-90 text--small-3 text--bold text--uppercase scale x-equal-6-left">{count}</span> : null}</p>
                    {count ? <div href="#"
                                  role="button"
                                  className="flex align-self-baseline text--small-2 hover--color text--brand-gray-60 text--to--brand-gray-24 scale x-equal-3-left"
                                  onClick={e => {
                                      e.preventDefault()
                                      setWatchList(removeAllFromWatchList(watchList, T_PRODUCTS))
                                  }}>{T("T_DELETE_ALL")}</div> : null}
                    <div href="#"
                         role="button"
                         className="ml-auto close-to-counter"
                         ref={toggle}
                         onClick={e => {
                             e.preventDefault()
                             setHidden(!hidden)
                         }}
                    >
                        <svg className="icon icon--small">
                            <use xlinkHref="#Icon-Close" xlinkTitle="Schliessen"/>
                        </svg>
                    </div>
                </div>

                <div
                    className={`watchlist-body-wrapper ${count > 3 ? "show-scrollbar" : null} scale-padding x-equal-3-left`}>
                    <div className="watchlist-body" ref={watchListBody}>
                        {count ? null : (
                            <div className="scale y-equal-5-top y-equal-5-bottom">
                                <div className="bg--brand-gray-95 y-scale-padding y-equal-5-top y-equal-5-bottom">
                                    <p className="text--tighter text--bold text--center">{T(WATCH_LIST_EMPTY)}</p>
                                </div>
                                <p className="text--block text--brand-gray-60 text--small-2 scale y-equal-5-top">Klicken
                                    Sie in der Produkt-Liste auf einen Pin um dieses Produkt zu Ihrer Merkliste
                                    hinzuzufügen.</p>
                            </div>
                        )}
                        <CardList
                            entries={products}
                            removeItem={id =>
                                setWatchList(removeFromWatchList(watchList, T_PRODUCTS, id))
                            }
                        >
                        </CardList>
                    </div>
                </div>
                {count ? (
                    <div
                        className="watchlist-footer bg--brand-gray-95 scale-padding x-equal-3-left x-equal-3-right y-equal-5-top y-equal-5-bottom">
                        <div className="watchlist-footer__item watchlist-footer__item--border">
                            <p className="text--small-2 text--bold scale y-equal-6-bottom">{T("T_SAFE_LIST")}</p>
                            <ul className="sharing-button-list">
                                <li>
                                    <div
                                        role="button"
                                        className="counter border--transparent bg--brand-white hover border--to--brand-gray-80 bg--to--brand-gray-93"
                                        href="#"
                                        target="_blank"
                                        onClick={e => {
                                            e.preventDefault()
                                            pushToDataLayer('saveProducts', 'Watchlist', 'Order Product', products)
                                            // products.map(product => pushToDataLayer('saveProducts', 'Watchlist', 'Order Product', product.number))
                                            showPrintView(watchList)
                                        }}
                                    >
                                        <svg className="icon icon--medium icon--brand-primary-color-50">
                                            <use xlinkHref="#Icon-Print"/>
                                        </svg>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="watchlist-footer__item">
                            <p className="text--small-2 text--bold scale y-equal-6-bottom">{T("T_ORDER_LIST")}</p>
                            <ul className="sharing-button-list ">
                                <li className="scale x-equal-5-right">
                                    <a
                                        className="inter-tooltip__parent border--transparent counter bg--brand-white hover border--to--brand-gray-80 bg--to--brand-gray-93"
                                        target="_blank"
                                        onClick={() => {
                                            pushToDataLayer('orderMail', 'Watchlist', 'Order Product', products)
                                        }}
                                        href={`mailto:info@grauwiler.ch?subject=Produkte aus Merkliste bestellen&body=Guten Tag%0D%0DIch würde gerne folgende Artikel bestellen:%0D${products.map(product => ("%0D" + product.title + ", " + product.number))}`}
                                    >
                                        <span
                                            className="inter-tooltip text--small-4 bg--brand-white">info@grauwiler.ch</span>
                                        <svg className="icon icon--medium icon--brand-primary-color-50">
                                            <use xlinkHref="#Icon-E-Mail"/>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="inter-tooltip__parent border--transparent counter bg--brand-white hover border--to--brand-gray-80 bg--to--brand-gray-93"
                                        target="_blank"
                                        onClick={() => {
                                            pushToDataLayer('orderTel', 'Watchlist', 'Order Product', products)
                                        }}
                                        href="tel:+41613851000"
                                    >
                                        <span
                                            className="inter-tooltip text--small-4 bg--brand-white">+41 61 385 10 00</span>
                                        <svg className="icon icon--medium icon--brand-primary-color-50">
                                            <use xlinkHref="#Icon-Phone"/>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                ) : null}
            </div>
        </div>
    )
}

function CardList({entries, heading, removeItem, children}) {
    if (!entries.length) return null

    return (
        <>
            <div className="card-list ">
                {entries.map(entry => (
                    <Card key={entry.id} removeItem={removeItem} {...entry} />
                ))}
            </div>
            {children}
        </>
    )
}

function Card({color, number, title, title_addition, url, id, removeItem}) {
    const handleRemove = e => {
        e.preventDefault()
        removeItem(id)
    }

    return (
        <div
            className={`product-card border--bottom border--brand-gray-80 y-scale-padding y-equal-5-top y-equal-5-bottom`}>
            <a className="scale x-equal-4-right card-interaction counter counter--small" href="#"
               onClick={handleRemove}>
                <svg
                    className="icon icon--tiny icon--brand-gray-60"
                    dangerouslySetInnerHTML={{__html: '<use xlink:href="#Icon-Delete" />'}}
                />
            </a>
            <div className="product-card__body">
        <span className={`text--small-3 text--uppercase text--brand-gray-60 scale y-equal-7-bottom`}>
          {number}
        </span>
                <span className={`text--small`}>
          {title}
                    <span className={`scale x-equal-5-left text--brand-gray-60`}>
            {title_addition}
          </span>
        </span>
            </div>

        </div>
    )
}

const $ = window.Bliss
const $$ = $.$;

const {T} = window

const MIN_ATTACHED_CLASS_DURATION = 800

function emitChangeEvent(el) {
  if (el.createEventObject) {
    el.fireEvent("onchange")
  } else {
    const evt = document.createEvent("HTMLEvents")
    evt.initEvent("change", false, true)
    el.dispatchEvent(evt)
  }
}

/*
 * Replace an element in the current document with an element from doc
 */
export function replaceElement({doc, selector}) {
  const oldEl = $(selector)
  if (!oldEl) return // Unlikely. But might be gone already.
  const newEl = $(selector, doc)
  if (newEl) {
    oldEl.parentElement.replaceChild(newEl, oldEl)
  } else {
    oldEl.parentElement.removeChild(oldEl)
  }
}

/*
 * Append children of an element from doc to the same element in the current
 * document
 */
export function appendChildren({doc, selector}) {
  const target = $(selector)
  if (!target) return // Unlikely. But might be gone already.
  const fragment = document.createDocumentFragment()
  $$(`${selector}>*`, doc).forEach(child => fragment.appendChild(child))
  target.appendChild(fragment)
}

/*
 * Adds or removes a list of classes on the body element
 */
function setBodyClasses(classes, add) {
  classes.forEach(cl => {
    document.body.classList[add ? "add" : "remove"](cl)
  })
}

export class HTMLLoader {
  constructor({
    bodyClasses = ["fetching"],
    showErrorAbove = ".container.main",
    handlers = [],
  }) {
    this.bodyClasses = bodyClasses
    this.showErrorAbove = $(showErrorAbove)
    this.handlers = handlers
    this.attachedBodyClassesAt = null

    // console.log(this.bodyClasses);
    // console.log(this.showErrorAbove);
    // console.log(this.handlers);
    // console.log(this.attachedBodyClassesAt);
  }

  /*
   * Fetches the whole content from a requested url.
   * This is the only method that should be called from the "outside"
   *
   * TODO: Maybe return a promise if it is necessary to observe the
   * state of this action.
   */
  fetch(url) {
    return new Promise((resolve, reject) => {
      setBodyClasses(this.bodyClasses, true)
      this.attachedBodyClassesAt = new Date()
      this._hideErrors()

      fetch(url)
        .then(res => {
          return res.ok ? res.text() : new Error(res.statusText)
        })
        .then(data => {
          const parser = new DOMParser()
          const doc = parser.parseFromString(data, "text/html")
          this.handlers.forEach(handler => handler(doc))
          $(document.body)._.fire("htmlLoader")
          resolve()
        })
        .catch(error => {
          this._handleError(error)
          reject(error)
        })
        .then(() => {
          let delta = new Date() - this.attachedBodyClassesAt
          emitChangeEvent($(document.body))
          setTimeout(
            () => {
              setBodyClasses(this.bodyClasses, false)
            },
            delta >= MIN_ATTACHED_CLASS_DURATION
              ? 0
              : MIN_ATTACHED_CLASS_DURATION - delta
          )
        })
    })
  }

  _errorElement() {
    const el = document.createElement("div")
    el.classList.add("error")
    el.textContent = T("LOADER_ERROR")
    return el
  }

  _handleError = error => {
    console.error(error)
    this.showErrorAbove.insertAdjacentElement(
      "beforebegin",
      this._errorElement()
    )
  }

  _hideErrors() {
    $$(".error", this.showErrorAbove.parentElement).forEach(el =>
      el.parentElement.removeChild(el)
    )
  }
}

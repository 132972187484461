import {localStorage} from "./storage";

const $ = window.Bliss
import {HTMLLoader, replaceElement, appendChildren} from "./htmlLoader"
import formDataEntries from "form-data-entries"

function emitChangeEvent(el) {
    if (el.createEventObject) {
        el.fireEvent("onchange")
    } else {
        const evt = document.createEvent("HTMLEvents")
        evt.initEvent("change", false, true)
        el.dispatchEvent(evt)
    }
}

export function initFilter(form) {


    const filterFormLoader = new HTMLLoader({
        bodyClasses: ["fetching", "fetching-filters"],
        showErrorAbove: "[data-filter-form]",
        handlers: [
            doc => (document.title = doc.title || document.title),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-products-filter] .filter-list",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-filter-fixed] .filter-controls__open--left",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-filter-selection] .filter-selection",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-products-filter] .featured-filters",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-products-filter] .filter-overlay__header",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-products-filter] .filter-overlay__footer",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-products-header] .assortment-list__header",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-products-header] .assortment-list__filter",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-filter-controls] .filter-controls__filter",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-filter-form] .search-field",
                }),
            doc =>
                replaceElement({
                    doc,
                    selector: "[data-filter-no-results] .assortment-list__no-results",
                }),
            doc => replaceElement({doc, selector: "[data-filter-results]"}),
            doc => replaceElement({doc, selector: "[data-load-more-container]"}),
            // doc => replaceElement({doc, selector: "[data-products-header]"}),
            () => {
                document._.fire('initSoftpageTrigger');
            }
        ],
    })

    const getFilterParams = () => {
        const params = new URLSearchParams()
        for (let [key, value] of formDataEntries(form)) {
            if (value) {
                params.append(key, value)
            } else {
                params.delete(key);
            }
        }
        return `?${params.toString()}`
    }

    history.replaceState({filterParams: getFilterParams()}, "")

    const fetchFilterForm = filterParams => {
        filterFormLoader.fetch(filterParams).then(() => {
            // const el = qs("[data-filter-results]")
            // el && el.scrollIntoView({behavior: "smooth", block: "nearest"})
        })
    }

    window.addEventListener("popstate", e => {
        if (e.state) {
            fetchFilterForm(e.state.filterParams)
        }
    })

    form.addEventListener("change", () => {
        const filterParams = getFilterParams()
        history.pushState({filterParams}, "", filterParams)
        fetchFilterForm(filterParams)
    })

    $.delegate(document.body, "click", "[data-filter-field]", function (e) {
        let getFilteredState = localStorage.getItem("hasFiltered")
        if (!getFilteredState) {
            localStorage.setItem("hasFiltered", true)
        }
        e.preventDefault()
        let target = e.target.closest('[data-filter-field]')
        $(target.dataset.filterField, form).value = target.dataset.filterValue
        emitChangeEvent(form)

    })
    $.delegate(document.body, "click", "[data-filter-interaction]", function (e) {
        e.preventDefault()
        let target = e.target.closest('[data-filter-interaction]')
        $(target.dataset.interaction, form).value = target.dataset.interaction
        emitChangeEvent(form)
    })


    $.delegate(document.body, "click", "[data-filter-reset]", function (e) {
        e.preventDefault()
        let filter = $("input#id_filters", form)
        let sort = $("a#id_sort", form)
        let searchFilter = $("input#search", form)

        if (filter) {
            filter.value = ""
        } else if (sort) {
            sort.value = ""
        }

        searchFilter.value = ""
        emitChangeEvent(form)
    })

    $.delegate(document.body, "click", "[data-filter-reset-search]", function (e) {
        e.preventDefault()
        let searchFilter = $("input#search", form)
        searchFilter.value = ""
        emitChangeEvent(form)
    })

    const loadMoreLoader = new HTMLLoader({
        bodyClasses: ["fetching", "fetching-cards"],
        showErrorAbove: "[data-load-more]",
        handlers: [
            doc => (document.title = doc.title || document.title),
            doc => replaceElement({doc, selector: "[data-load-more]"}),
            doc => appendChildren({doc, selector: "[data-load-target]"}),
            () => {
                document._.fire('initSoftpageTrigger');
            }
        ],
    })
    $.delegate(document.body, "click", "[data-load-more]", function (e) {
        e.preventDefault()
        let target = e.target.closest('[data-load-more]')
        loadMoreLoader.fetch(target.dataset.loadMore)
    })
}

export function triggerClassOnScroll(options) {
    // init options
    if (!options.element) {
        console.error('triggerClassOnScroll: No element set');
    }

    // init vars
    let class_to_trigger = options.class_to_trigger,
        scroll = options.scroll || 0,
        element = options.element,
        position = options.position;

    let ticking = false;
    let scrollPos = position.pageYOffset;

    if (!options.window) {
        scrollPos = position.scrollTop;
    }

    // handler
    function scrollHandler() {
        if (scrollPos >= scroll && !$(element).hasClass(class_to_trigger)) {
            $(element).addClass(class_to_trigger);
        } else if (
            scrollPos < scroll &&
            $(element).hasClass(class_to_trigger)
        ) {
            $(element).removeClass(class_to_trigger);
        }

        ticking = false;
    }

    function requestTick() {
        if (!ticking) {
            requestAnimationFrame(scrollHandler);
            ticking = true;
        }
    }

    // on page load
    scrollHandler();

    // on scroll

    if (options.window) {
        window.addEventListener('scroll', () => {
            scrollPos = position.pageYOffset;
            requestTick();
        });

    } else {
        position.addEventListener('scroll', () => {
            scrollPos = position.scrollTop;
            requestTick();
        });

    }

    return this;
}

import {localStorage} from "./storage";

const $ = window.Bliss

export function initTooltip() {
    console.log('initTooltip');
    let params = new URLSearchParams(window.location.search)
    let getFilteredState = localStorage.getItem("hasFiltered")
    if (params.has('filters') && !getFilteredState) {
        console.log('param');
        $("html").classList.add("tooltip--active")
        localStorage.setItem("hasFiltered", true)
    }
}



const $ = window.Bliss

export class overlayHandler {
    constructor(el) {
        this.selector = el
        this.animation = el.getAttribute("data-overlay-type")
        this.isOpen = false
        this.body = $("body")
        this.closeMessageLink = $("[data-close-message]")
        this.addListeners()

    }

    addListeners = () => {

        if (this.closeMessageLink) {
            this.closeMessageLink.addEventListener("click.message", e => {
                e.preventDefault()
                $("html").classList.remove("tooltip--active")
            })
        }
        document.body.addEventListener("click.overlay", (e) => {
            if (e.target.closest("[data-overlay-open]")) {
                e.preventDefault()
                this.target = e.target.closest('[data-overlay-open]')
                this.openOverlay()
            } else if ((!e.target.closest('[data-filter-overlay]') || e.target.closest('[data-filter-close]')) && this.isOpen) {
                this.closeOverlay()
                return () => {
                    document.body._.unbind("click.overlay")
                }
            }
        })
        $.delegate(document.body, "click.overlay", "[data-filter-close]", (e) => {
            e.preventDefault()
            this.target = e.target.closest('[data-filter-open]')
            if ((!e.target.closest('[data-filter-overlay]') || e.target.closest('[data-filter-close]')) && this.isOpen) {
                this.closeOverlay()
            } else if (!this.isOpen && this.target) {
                this.openOverlay()
            }
            return () => {
                document.body._.unbind("click.overlay")
            }
        })
        document.addEventListener('keydown.overlay', (e) => {
            if (e.key === "Escape" && this.isOpen) {
                this.closeOverlay()
            }
            return () => {
                document.body._.unbind("keydown.overlay")
            }
        })
    }

    openOverlay = () => {
        this.isOpen = true
        this.selector.classList.add('overlay--open')
        this.body.classList.add('overlay--open')
        if ($("html").classList.contains("tooltip--active")) {
            $("html").classList.remove("tooltip--active")
        }
    }

    closeOverlay = () => {
        this.isOpen = false
        this.selector.classList.remove('overlay--open')
        this.body.classList.remove('overlay--open')
        if ($("html").classList.contains("tooltip--active")) {
            $("html").classList.remove("tooltip--active")
        }
    }
}

const $ = window.Bliss

export class OffCanvas {
    constructor(el) {
        this.selector = el
        this.animation = el.getAttribute("data-animation")
        this.isOpen = false
        this.addListeners()
    }

    addListeners = () => {

        $.delegate(document.body, "click.canvas", '[data-open-canvas]', e => {
            e.preventDefault()
            this.target = e.target.closest('[data-open-canvas]')

            if (this.target && !this.isOpen) {
                this.openCanvas()
            }
            return () => {
              document.body._.unbind("click.canvas")
          }
        })

        $.delegate(document.body, "click.canvas", '[data-close-canvas]', e => {
            e.preventDefault()
            this.target = e.target.closest('[data-close-canvas]')

            if (this.target && this.isOpen) {
                this.closeCanvas()
            }
                 return () => {
              document.body._.unbind("click.canvas")
          }
        })

       document.addEventListener('keydown', (e) => {
            if (e.key === "Escape") {
                this.closeCanvas()
            }
        })

    }

    openCanvas = () => {
        this.isOpen = true
        this.selector.classList.add('offcanvas--open')
        $("html").classList.add('offcanvas--open')
    }

    closeCanvas = () => {
        this.isOpen = false
        this.selector.classList.remove('offcanvas--open')
        $("html").classList.remove('offcanvas--open')
    }
}

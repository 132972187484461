/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Essentials

*/

import jQuery from 'jquery'; // eslint-disable-line
import 'blissfuljs';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

NPM Packages

*/
// eslint-disable-next-line
import 'lazysizes/plugins/print/ls.print'; // needs to be BEFORE the main lazysizes script
import 'lazysizes'; // eslint-disable-line
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

allink CORE (NPM package)

===

These modules provide core functionality.

Note: Should you need a customized project specific version of one of
the following modules:

Head over to the 'allink-core-static' repo, comment out the module below,
download the latest version and place it in the ./modules folder.

*/
import './static-core';


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Custom Scripts

*/
import './modules/ajax-load-items';
import './modules/header';
import './modules/map';
import './modules/navigation';
import './modules/parallax';
import './modules/swiper';
import './modules/seo-accordion';
import './modules/helpers';
import {Toggle} from './modules/toggle';
import {OffCanvas} from "./modules/offCanvas";
// import './modules/smooth-scroll';
import {renderWatchList} from "./assortment/watchList.js";
import {initFilter} from "./assortment/filter"
import {initTooltip} from "./assortment/tooltip"
import {overlayHandler} from "./assortment/overlayHandler";

const $ = window.Bliss;
const $$ = $.$;

$.ready().then(function () {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Initialize CMS-Plugins after Ajax-Request

    */

    if (typeof CMS !== 'undefined') {
        jQuery(window).on('ajaxLoadItems:success', () => {
            CMS.Plugin._initializeTree(); // eslint-disable-line
        });
    }

    let toggleSelector = $$('[data-toggle]');
    const offCanvasContainer = $('[data-offcanvas]')

    if (toggleSelector.length > 0) {
        new Toggle();
    }

    if (offCanvasContainer) {
        new OffCanvas(offCanvasContainer)
    }
});


document.addEventListener("DOMContentLoaded", () => {

    // determine and store scrollbarwidth as css variable
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
    document.documentElement.style.setProperty('--scroll-bar-width', scrollBarWidth + "px");


    $$("[data-watchlist]").forEach(el => renderWatchList(el))
    $$("[data-filter-form]").forEach(el => initFilter(el))
    $$("[data-filter-form]").forEach(el => initTooltip(el))
    const filterOverlay = $('[data-filter-overlay]')


    if (filterOverlay) {
        new overlayHandler(filterOverlay)
    }


    const filterControls = $('[data-filter-controls]')
    const filterToggle = $('[data-filter-fixed]')

    const options = {
        threshold: 0,
        rootMargin: "20px",
    }
    if (filterToggle) {
        let observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    filterToggle.classList.remove("appear")
                } else {
                    filterToggle.classList.add("appear")
                }
            })
        }, options)

        observer.observe(filterControls)
    }

})
